<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <e-date-time
                title="开始日期"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.BeginDate"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <e-date-time
                title="结束日期"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.EndDate"
              />
            </a-col>
            <a-col>
              <a-button type="primary" @click="getDataList(1)">查询</a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'createTime'">
              {{ $filters.formatDate(record.createTime) }}
            </template>

            <template v-if="column.key === 'planDate'">
              {{ $filters.formatDate(record.planDate, "YYYY-MM-DD") }}订购计划
            </template>

            <template v-if="column.key === 'state'">
              {{['未锁定', '已锁定'][record.state]}}
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>查看计划清单</template>
                <a
                  @click.stop="
                    routeTo('chef-plan-detailedlist', { planId: record.id })
                  "
                >
                  <OrderedListOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { OrderedListOutlined } from '@ant-design/icons-vue'
import { PlanClass } from '@/apis/plan'
import page from '@/mixins/page'

const api = new PlanClass()
export default defineComponent({
  mixins: [page],
  components: {
    OrderedListOutlined
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '计划日期',
        key: 'planDate',
        dataIndex: 'planDate'
      },
      {
        title: '创建日期',
        key: 'createTime',
        dataIndex: 'createTime',
        width: 200
      },
      {
        title: '商品数量',
        key: 'totalCount',
        dataIndex: 'totalCount',
        width: 100
      },
      {
        title: '采购金额',
        key: 'totalMoney',
        dataIndex: 'totalMoney',
        width: 100
      },
      {
        title: '状态',
        key: 'state',
        dataIndex: 'state',
        width: 120
      },
      {
        title: '查看清单',
        dataIndex: 'action',
        key: 'action',
        width: 100
      }
    ]
    return {
      columns,
      loading,
      height,
      width
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {

    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      if (q.BeginDate) {
        q.BeginDate = q.BeginDate.format('YYYY-MM-DD')
      }
      if (q.EndDate) {
        q.EndDate = q.EndDate.format('YYYY-MM-DD')
      }

      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },

    changeStatus (planid, e) {
      api.state(planid, e).then(() => {
        this.$message.success('修改状态成功！')
        this.getDataList(this.page.page)
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped></style>
